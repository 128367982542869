<template>
  <div>
    <post-header :header="data.header"></post-header>
    <v-container>
      <v-row justify="center" class="my-6">
        <v-col v-for="(item, index) in data.items" :key="'project-' + index"
               cols="12" md="6" lg="4">
          <feature-project :item="item"></feature-project>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import projectList from '../mock/ProjectList.json'
import FeatureProject from '../components/FeatureProject'
import PostHeader from '../components/PostHeader'
export default {
  name: 'ProjectList',
  components: { PostHeader, FeatureProject },
  data: function () {
    return {
      data: projectList
    }
  }
}
</script>

<style scoped>

</style>
